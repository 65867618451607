import React, { useCallback, useEffect, useState } from "react";
// @ts-ignore
import heart from "../../images/homePage/heart.svg";
import MaxWidthWrapper from "../common/MaxWidthWrapper";
import ContactPageBGCurve from "../../images/curve/ContactPageBGCurve";
import GeographicMapSvg from "../svg/GeographicMap";
import SecondaryWidthWrapper from "../common/SecondaryWidthWrapper";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import LoaderSvg from "../svg/loader.svg";

import { isValidPhoneNumber } from "react-phone-number-input";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import "react-phone-number-input/style.css";
import Select from "react-select";
import { Link } from "gatsby";
import { emailRegex } from "../../util/mix";
import axios from "axios";
import sha256 from "crypto-js/sha256";
import Hex from "crypto-js/enc-hex";

function hash(message: string) {
  const hashed = sha256(message);
  return hashed.toString(Hex);
}

const schema = yup.object().shape({
  purpose: yup.object().shape({
    label: yup.string().required("Purpose is required"),
    value: yup.string().required("Purpose is required"),
  }),
  name: yup.string().required("Name is required"),
  phone: yup
    .string()
    .required("Phone number is required")
    .max(15, "Please enter valid phone number"),
  email: yup
    .string()
    .email()
    .required("Email is required")
    .matches(emailRegex, "Please enter valid email"),
  companyName: yup.string().required("Company name is required"),
  message: yup.string().required("Message is required"),
});

interface ContactUsProps {
  title: string;
  titleGreen: string;
}

function gtag_report_conversion(userData: Record<string, string>) {
  // @ts-ignore
  window.gtag("event", "conversion", {
    send_to: "AW-386864266/1d6eCIXaxqcZEIqpvLgB",
    user_data: {
      name: hash(userData.name),
      phone_number: hash(userData.phone),
      email: hash(userData.email),
    },
  });
  return false;
}

const customStyles = {
  placeholder: (defaultStyles: any) => {
    return {
      ...defaultStyles,
      color: "#9CA4B7",
    };
  },
  control: (provided: any, _: any) => ({
    ...provided,
    border: "none",
    boxShadow: "none",
    color: "red",
    textOverflow: "ellipsis",
    backgroundColor: "transparent",
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#40BA77" : "white",
    boxBorder: "1px solid #40BA77",
    "&:hover": {
      backgroundColor: "#8ed7af",
      color: "white",
    },
  }),
};

const FormSubmitted = () => (
  <div className="ml-[-35px]">
    <svg
      width="400"
      height="200"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 400 200"
    >
      {/* Background */}
      <rect width="100%" height="100%" fill="transparent" />

      {/* Circle and Checkmark */}
      <g transform="translate(200, 70)">
        <circle cx="0" cy="0" r="50" fill="#4caf50">
          <animate
            attributeName="r"
            from="0"
            to="50"
            dur="0.5s"
            fill="freeze"
          />
        </circle>
        <text
          x="0"
          y="10"
          fontFamily="cursive"
          fontSize="40"
          textAnchor="middle"
          fill="#fff"
        >
          ✓
          <animate
            attributeName="opacity"
            from="0"
            to="1"
            begin="0.5s"
            dur="0.5s"
            fill="freeze"
          />
        </text>
      </g>

      {/* Success Message */}
      <text
        x="200"
        y="160"
        fontSize="24"
        textAnchor="middle"
        fill="#333"
        className="text-primary"
      >
        We will get back to you soon!
        <animate
          attributeName="opacity"
          from="0"
          to="1"
          begin="1s"
          dur="0.5s"
          fill="freeze"
        />
      </text>
    </svg>
  </div>
);

const ContactUs: React.FC<ContactUsProps> = ({ titleGreen, title }) => {
  const [successMsg, setSuccessMsg] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [optionError, setOptionError] = useState<string | null>(null);
  const [submitted, setSubmitted] = useState<boolean>(false);

  const selectOption = [
    {
      value: "sevice",
      label: "Looking to hire remote software engineers",
    },
    {
      value: "develop",
      label: "Looking for a team to develop a MVP/Website/Mobile App",
    },
    {
      value: "joinus",
      label: "Looking for a Job at Propelius Technologies",
    },
    {
      value: "other",
      label: "Other",
    },
  ];
  const {
    watch,
    register,
    handleSubmit,
    control,
    formState: { errors, isDirty, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur" || "onChange" || "onTouched",
  });
  const watchPurpose = watch("purpose");

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }

    return await executeRecaptcha("homepage");
  }, [executeRecaptcha]);

  const onSubmit = async (data: any) => {
    setIsLoading(true);
    data.token = await handleReCaptchaVerify();

    data.message = `Purpose: ${data.purpose.label}, Message: ${data.message}`;

    axios
      .post(
        `${process.env.GATSBY_API_URL}/contact-users`,
        { data },
        {
          headers: {
            Authorization: `Bearer ${process.env.GATSBY_API_KEY}`,
          },
        }
      )
      .then((res) => {
        if (res.data) {
          gtag_report_conversion(data);
          setSubmitted(true);
          setTimeout(() => {
            setSubmitted(false);
          }, 5000);
        }
        reset();
        setIsLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setIsLoading(false);
        setErrorMsg("Something went wrong.");
        setTimeout(() => {
          setErrorMsg("");
        }, 4000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const Form = () => (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={"flex w-full flex-col gap-1"}>
        <Controller
          name="purpose"
          control={control}
          render={({ field: { onChange, onBlur, value } }) => (
            <Select
              className={
                "mt-12 w-[100%] text-ellipsis border-b-[1px] border-b-primary bg-transparent focus:outline-none"
              }
              placeholder={"* Purpose"}
              styles={customStyles}
              options={selectOption}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
          )}
          rules={{ required: true }}
        />
        {errors.purpose && (
          <p className={"text-red-500"}>{errors?.purpose?.value?.message}</p>
        )}
      </div>

      {watchPurpose?.value === "joinus" ? (
        <div className={"flex w-full"}>
          <Link
            className={
              "mt-[50px] rounded-md bg-green-300 px-10 py-2 text-[14px] font-bold text-white xl:bg-primary xl:px-12 xl:text-[16px] xl:font-normal"
            }
            to={"/join-us"}
          >
            Join us
          </Link>
        </div>
      ) : (
        <>
          <div
            className={
              "flex w-full flex-wrap gap-x-[30px] xl:grid xl:grid-cols-2"
            }
          >
            <div className={"flex w-full flex-col gap-1"}>
              <input
                className={
                  "mt-12 w-full border-b-[1px] border-b-primary bg-transparent p-1 focus:outline-none"
                }
                // name={"name"}
                placeholder={"* Name"}
                {...register("name", { required: true })}
              />
              {errors.name && (
                <p className={"text-red-500"}>{errors.name.message}</p>
              )}
            </div>

            <div className={"flex w-full flex-col gap-1"}>
              <input
                className={
                  "mt-12 w-full border-b-[1px] border-b-primary bg-transparent p-1 focus:outline-none"
                }
                // name={"companyName"}
                placeholder={"* Company name"}
                {...register("companyName", { required: true })}
              />
              {errors.companyName && (
                <p className={"text-red-500"}>{errors.companyName?.message}</p>
              )}
            </div>

            <div className={"flex w-full flex-col gap-1"}>
              <input
                className={
                  "mt-12 w-full border-b-[1px] border-b-primary bg-transparent p-1 focus:outline-none"
                }
                type={"email"}
                placeholder={"* Email"}
                {...register("email", { required: true })}
              />
              {errors.email && (
                <p className={"text-red-500"}>{errors.email?.message}</p>
              )}
            </div>

            <div className={"flex w-full flex-col gap-1"}>
              <Controller
                name="phone"
                // @ts-ignore
                control={control}
                rules={{
                  required: true,
                  validate: (value) => isValidPhoneNumber(value),
                }}
                render={({ field: { onChange, value } }) => (
                  <PhoneInputWithCountry
                    placeholder="Enter phone number"
                    className={
                      "input-phone-number mt-12 w-full border-b-[1px] border-b-primary bg-transparent p-1 focus:outline-none xl:w-full"
                    }
                    name="phone"
                    control={control}
                    rules={{ required: true }}
                    defaultCountry={"IN"}
                    value={value}
                    onChange={onChange}
                  />
                )}
              ></Controller>

              {errors.phone && (
                <p className={"text-red-500"}>{errors.phone?.message}</p>
              )}
            </div>
          </div>

          <div className={"w-full"}>
            <textarea
              className={
                "mt-[42px] w-full resize-none rounded-xl border-[1px] border-primary bg-transparent p-4 focus:outline-none"
              }
              rows={4}
              placeholder={"* Write a message..."}
              {...register("message", { required: true })}
            />
            {errors.message && (
              <p className={"text-red-500"}>{errors.message?.message}</p>
            )}
          </div>

          <button
            className={`mt-2 block flex items-start rounded ${
              !isValid || !isDirty || isLoading
                ? "bg-[#ababab]"
                : "bg-green-300"
            } px-10 py-2 font-semibold text-white drop-shadow-xs xl:mt-16 xl:text-[22px] xl:font-normal`}
            type={"submit"}
            disabled={!isValid || !isDirty || isLoading}
          >
            {isLoading && <LoaderSvg width={"w-4"} height={"h-4"} />}
            <span> Submit </span>
          </button>
          {successMsg && <p className={"mt-5 text-primary"}>{successMsg}</p>}
          {errorMsg && <p className={"mt-5 text-red-600"}>{errorMsg}</p>}
        </>
      )}
    </form>
  );

  return (
    <MaxWidthWrapper>
      <SecondaryWidthWrapper>
        <div
          id="contact-us"
          className={
            "relative mt-[80px] flex flex-col justify-center py-[55px] md:px-[80px] xl:flex-row  xl:gap-x-11"
          }
        >
          <div
            className={
              "absolute left-[20px] top-[20px] bottom-0 md:top-[10px] md:left-[90px]"
            }
          >
            <ContactPageBGCurve />
          </div>
          <div className={"flex flex-1"}>
            <div className={"relative w-full"}>
              <div
                className={
                  "absolute -z-10 block flex h-full w-full items-center justify-center xl:h-0 xl:w-0"
                }
              >
                <GeographicMapSvg />
              </div>
              <p
                className={
                  "reak-normal text-[25px] font-medium text-black-400 "
                }
              >
                {title}
                <br />
                <span className={"text-primary"}>{titleGreen}</span>
              </p>

              {optionError && <p className={"text-red-500"}>{optionError}</p>}

              {submitted ? <FormSubmitted /> : <Form />}
            </div>
          </div>

          <div className={"flex flex-1"}>
            <div className={"flex flex-col overflow-hidden"}>
              <div className={"hidden xl:block"}>
                <GeographicMapSvg />
              </div>
              <p className={"mt-[60px] text-center font-medium text-primary"}>
                Propelius Technologies
              </p>
              <p className={"text-center font-light text-[#111111]"}>
                Based in India&nbsp;
                <img src={heart} className={"inline"} alt={"heart icon"} />
                &nbsp; working worldwide
              </p>
            </div>
          </div>
        </div>
      </SecondaryWidthWrapper>
    </MaxWidthWrapper>
  );
};
export default ContactUs;
