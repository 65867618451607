import React from "react";
import MaxWidthWrapper from "../common/MaxWidthWrapper";
import SecondaryWidthWrapper from "../common/SecondaryWidthWrapper";

interface OurTechniqueProps {
  title: string;
  titleGreen: string;
  subtitle: string;
  subAction: React.ReactElement;
}

const OurTechnique: React.FC<OurTechniqueProps> = ({
  title,
  titleGreen,
  subtitle,
  subAction,
}) => {
  return (
    <>
      <div className={"bg-gradient-to-b to-hero-gradient-end"}>
        <MaxWidthWrapper>
          <SecondaryWidthWrapper>
            <div>
              <div
                className={
                  "mt-[50px] flex flex-col gap-[10px] py-[10px] lg:mt-[125px]"
                }
              >
                <div className={"w-full"}>
                  <h1
                    className={
                      "text-center text-[20px] font-semibold text-black-400 " +
                      "lg:text-left lg:text-[22px] lg:leading-[45px] " +
                      "xl:text-left xl:text-[31px] xl:leading-[50px] " +
                      "2xl:text-left 2xl:text-[38px] 2xl:leading-[57px]"
                    }
                  >
                    {title}&nbsp;
                    <span className={"text-primary"}>{titleGreen}</span>
                  </h1>
                </div>
                <div>
                  <h5
                    className={
                      "text-center text-[14px] text-[#2F4651] lg:text-left xl:text-left xl:text-xl 2xl:text-left 2xl:text-2xl 2xl:font-normal"
                    }
                  >
                    {subtitle}
                  </h5>
                </div>
              </div>
              <div
                className={
                  "m-auto mb-[100px] flex items-center justify-center "
                }
              >
                {subAction}
              </div>
            </div>
          </SecondaryWidthWrapper>
        </MaxWidthWrapper>
      </div>
    </>
  );
};

export default OurTechnique;
