import * as React from "react";
import { useEffect, useState } from "react";
import HeroPoster from "../components/heroPoster/heroPoster";
import JoinMovement from "../components/heroPoster/JoinMovement";
import OurTechnique from "../components/heroPoster/ourTechnique";
import OurTechniqueList from "../components/heroPoster/ourTechniqueList";
import TopProject from "../components/heroPoster/TopProject";
import OurExperienceComponent from "../components/home/OurExperience.component";
import OurTechnologiesComponent from "../components/home/OurTechnologies.component";
import Layout from "../components/layout";
import { primaryBlockDataHandler } from "../helper/api/primaryBlock.api";
import SecondaryBlockType from "../types/SecondaryBlock.type";
// @ts-ignore
import { Helmet } from "react-helmet";
import ContactUs from "../components/heroPoster/ContactUs";
import ProcessList from "../components/heroPoster/ProcessList";
import CompaniesWorkedWithComponent from "../components/home/CompaniesWorkedWith.component";
import { SEO } from "../components/seo";
import SeoDataHandler from "../helper/handler/seoData.handler";
import HomeHeroPosterCurve2 from "../images/curve/HomeHeroPosterCurve2";
import HomePageCurve1 from "../images/curve/HomePageCurve1";
import JoinUsCurve1 from "../images/curve/JoinUsCurve1";
import JoinUsCurve2 from "../images/curve/JoinUsCurve2";
import ProjectsCurve1 from "../images/curve/ProjectsCurve1";
import ProjectsCurve2 from "../images/curve/ProjectsCurve2";
import WeBuildCurve1 from "../images/curve/WeBuildCurve1";
import WeBuildCurve2 from "../images/curve/WeBuildCurve2";
import WhyUsCurve1 from "../images/curve/WhyUsCurve1";
import WhyUsCurve2 from "../images/curve/WhyUsCurve2";
import { primaryBlockDataType } from "../types/PrimaryBlock.type";

const IndexPage: React.FC<any> = ({ pageContext }) => {
  const [clientAllData, setClientAllData] = useState<any[]>([]);
  const [clientData, setClientData] = useState<any>([]);
  const [tagData, setTagData] = useState<any>([]);
  const [selectedCate, setSelectedCate] = useState<string>("All");
  const [ourTechniqueListData, setOurTechniqueListData] = useState<any>();
  const [topProjectData, setTopProjectData] = useState<any>();
  const [joinOurCompany, setJoinOurCompany] = useState<
    SecondaryBlockType | undefined
  >();
  const [CompanyAgileProcess, setCompanyAgileProcess] = useState<
    primaryBlockDataType | undefined
  >();

  useEffect(() => {
    const cate = pageContext?.allTag?.data
      ?.map(
        (cate: any) =>
          cate?.attributes?.portfolios?.data.length && cate?.attributes.name
      )
      .filter((item: any) => item);
    setTagData(cate);
    const tempData = primaryBlockDataHandler(
      pageContext?.ourTechnologyListData
    );
    const companyAgileProcessData = primaryBlockDataHandler(
      pageContext.companyProjectAgileProcess
    );
    setCompanyAgileProcess(companyAgileProcessData);
    setOurTechniqueListData(tempData);
    setTopProjectData(pageContext?.topProjectWithClient?.topProjectData);
    setClientAllData(pageContext?.topProjectWithClient?.clientAllData);
    setClientData(pageContext?.topProjectWithClient?.setClientData);
    setJoinOurCompany(pageContext?.joinUsData);
  }, []);

  useEffect(() => {
    if (selectedCate !== "All") {
      const filterData = clientAllData.filter((project) => {
        const isIncluded = project?.attributes?.tags?.data.find((tag: any) => {
          return tag?.attributes?.name === selectedCate;
        });
        if (isIncluded) {
          return true;
        }
      });
      setClientData(filterData);
    } else {
      setClientData(clientAllData);
    }
  }, [selectedCate, topProjectData]);

  return (
    <Layout
      footerData={pageContext?.common?.footerData}
      navbarData={pageContext?.common?.navbarData}
    >
      <div className={"z-1 relative"}>
        <HeroPoster
          pageData={pageContext?.homePageData?.pageData}
          coverUrl={pageContext?.homePageData?.pageMedia}
        />
        <CompaniesWorkedWithComponent
          companiesWorkWith={pageContext?.companyWorkWith}
        />
        <OurExperienceComponent
          ourExperiencesData={pageContext?.ourExperienceData}
        />
        <OurTechnologiesComponent
          technologyAgnostic={
            pageContext?.ourTechnologyData?.setTechnologyAgnostic
          }
          allTechnology={pageContext?.ourTechnologyData?.AllTechnology}
        />
        <ProcessList
          titleSection={
            CompanyAgileProcess?.titleSection
              ? CompanyAgileProcess?.titleSection
              : {}
          }
          data={
            CompanyAgileProcess?.dataSection
              ? CompanyAgileProcess?.dataSection
              : []
          }
        />
        <TopProject
          primaryTitle={topProjectData?.titleSection?.primaryTitle}
          secondaryTitle={topProjectData?.titleSection?.secondaryTitle}
          tags={["All", ...tagData]}
          clients={clientData}
          button={{
            text: topProjectData?.button?.text,
            url: topProjectData?.button?.url,
          }}
          selectedCate={selectedCate}
          setSelectedCate={setSelectedCate}
        />
        <OurTechnique
          title={ourTechniqueListData?.titleSection?.primaryTitle}
          titleGreen={ourTechniqueListData?.titleSection?.secondaryTitle}
          subtitle={ourTechniqueListData?.titleSection?.primaryDescription}
          subAction={
            <OurTechniqueList data={ourTechniqueListData?.dataSection} />
          }
        />
        {joinOurCompany && (
          <JoinMovement
            data={joinOurCompany?.data}
            meta={joinOurCompany?.meta}
          />
        )}
        <div>
          <ContactUs
            title="Let’s Make It Happen"
            titleGreen="Get Your Free Quote Today!"
          />
        </div>
        <div
          className={
            "-z-1 absolute top-5 bottom-0 left-0 right-0 hidden h-[500px] lg:block"
          }
        >
          <HomePageCurve1 />
        </div>
        <div
          className={
            " absolute top-0 bottom-0 left-0 right-0 hidden h-[500px] w-[500px] lg:block"
          }
        >
          <HomeHeroPosterCurve2 />
        </div>
        <div className={" absolute top-[900px] right-0 hidden md:block"}>
          <WeBuildCurve1 />
        </div>
        <div className={"absolute top-[1020px] right-0 hidden md:block"}>
          <WeBuildCurve2 />
        </div>
        <div
          className={
            " absolute top-[3050px] left-0 hidden h-[1100px] sm:hidden lg:block"
          }
        >
          <ProjectsCurve1 />
        </div>
        <div
          className={
            " absolute top-[3150px] left-0 hidden h-[1200px] sm:hidden lg:block"
          }
        >
          <ProjectsCurve2 />
        </div>
        <div
          className={
            "-z-1 absolute left-0 hidden h-[100px] sm:hidden md:top-[5814px] md:block lg:top-[4890px] xl:top-[5225px]"
          }
        >
          <WhyUsCurve1 />
        </div>
        <div
          className={
            "-z-1 absolute left-0 hidden h-[100px] sm:hidden md:top-[6050px] md:block lg:top-[5191px] xl:top-[5448px]"
          }
        >
          <WhyUsCurve2 />
        </div>
        <div
          className={
            "absolute right-0 -z-10 hidden h-[900px] sm:hidden md:top-[7200px] md:block lg:top-[5720px] xl:top-[5820px]"
          }
        >
          <JoinUsCurve1 />
        </div>
        <div
          className={
            "absolute right-0 -z-10 hidden h-[900px]  sm:hidden md:top-[7223px] md:block lg:top-[5800px] xl:top-[5900px]"
          }
        >
          <JoinUsCurve2 />
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const Head: React.FC<any> = ({ pageContext }) => {
  const seoData = SeoDataHandler(pageContext?.homePageData?.pageData);
  return (
    <>
      <Helmet>
        <meta
          name="google-site-verification"
          content="1A5wqS_9o5fdQnuxUQvCbvqQzkMPWDaVWWo7JJPUN_U"
        />
      </Helmet>
      <SEO
        title={seoData.title}
        description={seoData.description}
        keywords={seoData.keywords}
        image={seoData.image}
        pathname={seoData.pagePath}
      />
    </>
  );
};
