import React from "react";

const HomePageCurve1 = () => {
  return (
    <svg
      className={"md:w-[200px] lg:w-[500px]"}
      xmlns="http://www.w3.org/2000/svg"
      // width="343"
      height="700"
      fill="none"
      viewBox="0 0 343 825"
    >
      <path
        stroke="#B0C5B2"
        strokeOpacity="0.35"
        d="M-538.707 377.431l-.304.311.303-.311c-40.452-33.432-60.672-69.355-66.253-104.826-5.583-35.481 3.475-70.557 21.671-102.278 28.431-47.271 71.029-84.992 123.43-109.296C-342.397 7.3-187.764-11.504-49.264 9.731c209.979 32.39 397.225 214.304 391.547 411.103-5.06 175.978-145.015 335.001-317.151 387.821l-.002.001c-63.687 19.72-140.569 25.5-202.68-11.599-63.506-38.156-85.872-97.685-107.094-159.074-.804-2.326-1.607-4.655-2.41-6.985-20.368-59.109-41.087-119.234-97.921-162.512-22.597-17.231-48.202-30.136-73.723-42.999l-3.825-1.928c-26.799-13.522-53.341-27.236-76.184-46.128z"
      ></path>
    </svg>
  );
};

export default HomePageCurve1;
