import React from "react";

const WeBuildCurve2 = () => {
  return (
    <svg
      className={"w-[180px] lg:w-[284px]"}
      xmlns="http://www.w3.org/2000/svg"
      // width="284"
      height="1690"
      fill="none"
      viewBox="0 0 284 2019"
    >
      <path
        stroke="#B0C5B2"
        strokeOpacity="0.6"
        d="M1452.89 1710.43l.49.08-.49-.08c-16.66 108.41-63.89 183.81-128.7 232.81-64.82 49.01-147.27 71.63-234.41 74.4-131.486 1.8-261.511-31.97-376.746-97.87-256.688-148.47-489.545-401.08-621.105-669.28-199.11-406.932-67.08-938.363 338.252-1145.098C792.552-79.472 1290.57-7.18 1614.34 238.96c120.14 90.863 228.85 220.508 232.08 371.381 2.84 154.471-89.52 259.669-186.88 364.714-3.68 3.968-7.36 7.934-11.05 11.904-93.88 101.081-189.22 203.741-205.12 351.601-6.37 58.83-.22 118.21 5.92 177.48.31 2.95.61 5.91.92 8.86 6.41 62.23 12.12 124.29 2.68 185.53z"
      ></path>
    </svg>
  );
};

export default WeBuildCurve2;
