import React from "react";

const JoinUsCurve1 = () => {
  return (
    <svg
      className={"md:w-[270px] lg:w-[370px] xl:w-[470px] 2xl:w-[677px]"}
      xmlns="http://www.w3.org/2000/svg"
      // width="677"
      height="1090"
      fill="none"
      viewBox="0 0 677 1141"
    >
      <path
        stroke="#B0C5B2"
        strokeOpacity="0.6"
        d="M911.53 153.781h.001c71.154 54.142 136.659 129.751 142.219 214.535 5.44 86.776-44.87 143.461-98.142 200.011a7069.581 7069.581 0 01-6.061 6.424c-51.352 54.394-103.576 109.71-109.055 192.496-2.205 32.925 2.792 66.482 7.775 99.942l.745 5.013c5.215 35.143 10.016 70.143 6.117 104.287l.492.061-.492-.06c-6.879 60.44-32.083 101.54-67.992 127.38-35.922 25.84-82.609 36.44-132.503 35.76-75.352-2.36-150.747-24.66-218.456-64.63-150.891-90.011-290.683-237.937-372.755-392.017C-60.803 449.235 1.848 154.111 229.112 48.382 432.345-46.191 719.757 7.169 911.53 153.78z"
      ></path>
    </svg>
  );
};

export default JoinUsCurve1;
