import React from "react";
import { Link } from "gatsby";
// @ts-ignore
import personEmoji from "../../images/memoji.png";
// @ts-ignore
import handElipse from "../../images/homePage/hand-drown-elipse.svg";
import SecondaryBlockType from "../../types/SecondaryBlock.type";
import MaxWidthWrapper from "../common/MaxWidthWrapper";
import SecondaryWidthWrapper from "../common/SecondaryWidthWrapper";

const JoinMovement: React.FC<SecondaryBlockType> = ({ data, meta }) => (
  <div
    className={"relative z-10 w-full bg-hero-gradient-start"}
    style={{ position: "inherit" }}
  >
    <MaxWidthWrapper>
      <SecondaryWidthWrapper>
        <div className={"relative z-10 h-[407px]  lg:h-[910px]"}>
          <div
            className={
              "absolute left-3/4 -top-[4%] h-[32.19%] w-[30%] lg:h-[350px] lg:w-[280px] xl:w-[350px]"
            }
          >
            <img
              src={`${data?.[0]?.attributes?.imageData?.[1]?.media.data?.[0].attributes.url}`}
              className={"rounded-md"}
              alt={"female"}
            />
          </div>
          <div
            className={
              "absolute left-0 top-[6%] h-[16.81%] w-[25%] lg:h-[350px] lg:w-[300px] xl:w-[400px]"
            }
          >
            <img
              className={"rounded-md"}
              alt={"Yellow person"}
              src={`${data?.[0]?.attributes?.imageData?.[0]?.media.data?.[0].attributes.url}`}
            />
          </div>
          <div
            className={
              "absolute left-[7%] top-[69%] h-[63px] w-[30%] lg:h-[200px] lg:w-[300px] xl:w-[350px]"
            }
          >
            <img
              className={"rounded-md"}
              alt={"Yellow person"}
              src={`${data?.[0]?.attributes?.imageData?.[2]?.media.data?.[0].attributes.url}`}
            />
          </div>
          <div
            className={
              "absolute right-0 bottom-[12%] h-[89px] w-[30%] lg:h-[350px] lg:w-[300px] xl:w-[400px] "
            }
          >
            <img
              className={"rounded-md"}
              alt={"Yellow person"}
              src={`${data?.[0]?.attributes?.imageData?.[3]?.media.data?.[0].attributes.url}`}
            />
          </div>
          <div
            className={
              "flex h-full flex-col items-center justify-center pb-[100px] md:pb-0 xl:pb-0"
            }
          >
            <img
              className={"w-16 xl:w-[148px] 2xl:w-[148px]"}
              src={personEmoji}
              alt={"person emoji"}
            />
            <p
              className={
                "my-0 w-[80%] py-[15px] text-center text-lg font-medium text-[#111111] md:w-[45%] lg:w-[33%] xl:w-[24%] 2xl:my-6 2xl:w-[30%] 2xl:text-2xl 2xl:font-semibold"
              }
            >
              {data?.[0]?.attributes?.titleSection?.primaryTitle}{" "}
              <span className={"relative -ml-2.5 p-3 text-center text-primary"}>
                {data?.[0]?.attributes?.titleSection?.secondaryTitle}
                <img
                  className={"absolute -top-[25%] -left-[0%] h-[150%] w-[100%]"}
                  src={handElipse}
                  alt={"eclipse icon"}
                />
              </span>
            </p>
            <Link
              className={
                "rounded-md bg-green-300 px-10 py-2 text-[14px] font-bold text-white xl:bg-primary xl:px-12 xl:text-[16px] xl:font-normal"
              }
              to={data?.[0]?.attributes?.button?.url}
            >
              {data?.[0]?.attributes?.button?.text}
            </Link>
          </div>
        </div>
      </SecondaryWidthWrapper>
    </MaxWidthWrapper>
  </div>
);

export default JoinMovement;
