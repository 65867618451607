import React from "react";

const ProjectsCurve2 = () => {
  return (
    <svg
      className={"lg:w-[900px] xl:w-[1100px] 2xl:w-[1371px]"}
      xmlns="http://www.w3.org/2000/svg"
      // width="1371"
      height="1608"
      fill="none"
      viewBox="0 0 1371 1348"
    >
      <path
        stroke="#B0C5B2"
        strokeOpacity="0.35"
        d="M1126.71 290.524l.1-.469-.1.469c85.92 12.391 145.6 46.987 184.32 94.331 38.73 47.353 56.52 107.497 58.55 171.021 1.18 95.857-25.85 190.565-78.31 274.419C1173.05 1017.1 972.287 1186.29 759.36 1281.6c-323.047 144.24-744.198 46.78-907.365-249.18-145.91-264.594-87.649-627.51 108.14-862.988C32.415 82.052 135.404 3.114 255.006 1.105 377.465-.62 460.692 66.928 543.822 138.162c3.142 2.693 6.285 5.39 9.429 8.09C633.236 214.928 714.498 284.7 831.77 296.629c46.656 4.778 93.777.426 140.789-3.916l7.033-.648c49.368-4.536 98.588-8.558 147.118-1.541z"
      ></path>
    </svg>
  );
};

export default ProjectsCurve2;
