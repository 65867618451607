import React from "react";

const WhyUsCurve1 = () => {
  return (
    <svg
      className={"md:w-[400px] lg:w-[650px] xl:w-[850px] 2xl:w-[1043px]"}
      xmlns="http://www.w3.org/2000/svg"
      // width="1043"
      height="1436"
      fill="none"
      viewBox="0 0 1043 1436"
    >
      <path
        stroke="#B0C5B2"
        strokeOpacity="0.6"
        d="M476.313 1334.31l.407.28-.407-.28c-40.629 60.56-90.116 90.44-142.591 98.16-52.493 7.72-108.04-6.73-160.778-34.94-78.912-44.02-146.877-109.3-196.927-189.15-111.019-178.92-178.319-413.371-180.794-622.578C-208.232 268.565 21.04-11.076 321.216 1.586c268.428 11.297 544.945 226.428 668.296 488.118 45.908 96.822 73.988 213.405 33.198 306.744-42.038 95.391-126.847 128.019-214.687 158.859-3.322 1.166-6.649 2.33-9.979 3.495-84.622 29.606-170.638 59.698-222.05 144.758-20.477 33.81-33.64 72.27-46.769 110.63-.655 1.91-1.31 3.83-1.966 5.74-13.808 40.27-27.98 80.18-50.946 114.38z"
      ></path>
    </svg>
  );
};

export default WhyUsCurve1;
