import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import TitleWithLine from "../common/TitleWithLine";
import MaxWidthWrapper from "../common/MaxWidthWrapper";
import SecondaryBlockType from "../../types/SecondaryBlock.type";
import { secondaryBlockDataHandler } from "../../helper/api/secondaryBlock.api";
import SecondaryWidthWrapper from "../common/SecondaryWidthWrapper";
import { Autoplay } from "swiper";

interface CompaniesWorkedWithComponentProps {
  companiesWorkWith: SecondaryBlockType;
}

const CompaniesWorkedWithComponent: React.FC<
  CompaniesWorkedWithComponentProps
> = ({ companiesWorkWith }) => {
  const updatedOurFortuneCompanyData =
    secondaryBlockDataHandler(companiesWorkWith);

  return (
    <div>
      <TitleWithLine
        primaryTitle={updatedOurFortuneCompanyData?.titleSection?.primaryTitle}
        secondaryTitle={
          updatedOurFortuneCompanyData?.titleSection?.secondaryTitle
        }
      />
      <MaxWidthWrapper>
        <SecondaryWidthWrapper>
          <div className={"py-[16px] md:py-[32px]"}>
            <Swiper
              className={"m-[24px]"}
              breakpoints={{
                1280: {
                  slidesPerView: 5,
                },
                1024: {
                  slidesPerView: 4,
                },
                768: {
                  slidesPerView: 3,
                },
                0: {
                  slidesPerView: 2,
                },
              }}
              loop={true}
              autoplay={{
                delay: 2500,
                reverseDirection: true,
              }}
              modules={[Autoplay]}
            >
              {updatedOurFortuneCompanyData?.imageData?.map(
                (imageItem, index) => (
                  <SwiperSlide
                    key={index}
                    className={"!z-[-1] flex justify-center"}
                  >
                    <div className={"w-[150px]"}>
                      <a
                        className={"flex justify-center "}
                        href={imageItem?.url}
                        target={"_blank"}
                      >
                        <img
                          className={"object-cover object-center"}
                          src={imageItem?.media}
                          alt={imageItem?.altText}
                        />
                      </a>
                    </div>
                  </SwiperSlide>
                )
              )}
            </Swiper>
          </div>
        </SecondaryWidthWrapper>
      </MaxWidthWrapper>
    </div>
  );
};
export default CompaniesWorkedWithComponent;
