import React from "react";

const HomeHeroPosterCurve2 = () => {
  return (
    <svg
      className={"md:w-[220px] lg:w-[520px]"}
      xmlns="http://www.w3.org/2000/svg"
      // width="474"
      height="825"
      fill="none"
      viewBox="0 0 474 930"
    >
      <path
        stroke="#B0C5B2"
        strokeOpacity="0.35"
        d="M-113.591 898.812c70.286 41.983 157.204 35.405 229.073 13.151C309.77 852.346 467.736 672.874 473.448 474.22 479.86 251.99 268.436 46.619 31.38 10.053c-156.293-23.962-330.778-2.746-463.335 57.89-59.179 27.449-107.288 70.049-139.394 123.434m457.758 707.435l.251-.389m-.251.389l.25-.389h.001m-.251.389c-74.546-44.79-98.953-115.613-123.736-187.53-22.985-66.696-46.293-134.331-110.22-183.011l-223.802-336.894m458.009 707.046c70.065 41.851 156.783 35.327 228.604 13.088l.001-.001c194.123-59.566 351.954-238.9 357.66-437.358C479.329 252.205 268.157 47.05 31.352 10.522M-113.34 898.423c-71.635-43.04-96.862-110.19-120.791-179.409-.906-2.621-1.81-5.245-2.715-7.871-22.969-66.657-46.325-134.435-110.394-183.222-25.475-19.425-54.341-33.974-83.12-48.479l-4.311-2.173c-30.219-15.248-60.154-30.715-85.921-52.024l-.326.335.326-.335c-45.627-37.708-68.437-78.229-74.732-118.244-6.298-40.024 3.921-79.587 24.443-115.363M31.352 10.522l.027-.467-.027.467s0 0 0 0zm0 0C-124.835-13.424-299.213 7.78-431.677 68.374m0 0h.001l-.278-.429.276.43.001-.001zm0 0c-59.098 27.41-107.14 69.951-139.204 123.264m0 0l.002-.003-.47-.258m.468.261l-.002.004-.466-.265"
      ></path>
    </svg>
  );
};

export default HomeHeroPosterCurve2;
