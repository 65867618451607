import React from "react";
import classNames from "classnames";

interface OurTechniqueListProps {
  data: Array<{
    order: number;
    imageData: {
      altText: string;
      media: string;
      url: string;
    };
    title: string;
    description: string;
    isLast?: boolean;
  }>;
}

const OurTechniqueList: React.FC<OurTechniqueListProps> = ({ data }) => (
  <div
    className={
      "grid-cols-1d grid py-[57px] px-[40px] md:grid-cols-2 md:gap-y-[3rem] md:gap-x-[3rem] md:gap-y-[5rem] lg:grid-cols-3 lg:pt-[100px] lg:pt-[102px]"
    }
  >
    {data?.length > 0 &&
      data?.map(({ imageData, description, title, isLast, order }) => (
        <div
          key={order}
          className={
            "flex flex-col items-center gap-[8px]  md:h-[300px] md:items-start md:gap-[15px] md:bg-inherit md:py-0 md:drop-shadow-md"
          }
          style={{
            position: "inherit",
            zIndex: 1,
            backgroundColor: "#ffffff",
          }}
        >
          <div
            className={
              "flex flex-col items-center px-[10px] pt-[34px] lg:items-start"
            }
          >
            <div
              className={
                "md:items:start flex items-center justify-center md:justify-start"
              }
            >
              <img
                height={"60px"}
                width={"60px"}
                src={imageData?.media}
                alt={title}
              />
            </div>
            <p
              className={
                "mt-4 text-center text-[17px] font-medium leading-[25.5px] text-description-tex-color lg:text-left"
              }
            >
              {title}
            </p>
            <p
              className={
                "mt-2 text-center text-description-lighter lg:text-left"
              }
            >
              {description}
            </p>
            <div
              className={classNames(
                "m-auto mt-11 h-[1px] w-[185px] bg-divider md:hidden",
                {
                  hidden: isLast,
                }
              )}
            />
          </div>
        </div>
      ))}
  </div>
);

export default OurTechniqueList;
