import React from "react";

const WeBuildCurve1 = () => {
  return (
    <svg
      className={"w-[140px] lg:w-[240px]"}
      xmlns="http://www.w3.org/2000/svg"
      // width="240"
      height="1470"
      fill="none"
      viewBox="0 0 240 1772"
    >
      <path
        stroke="#B0C5B2"
        strokeOpacity="0.6"
        d="M1780.04 2030.68l.5.06-.5-.06c-14.32 125.87-66.82 211.52-141.66 265.35-74.84 53.85-172.09 75.91-275.95 74.5-156.85-4.9-313.77-51.33-454.699-134.53-313.973-187.28-604.843-495.08-775.618-815.68C-126.413 933.862 3.921 319.55 477.031 99.447 900.016-97.386 1498.11 13.682 1897.16 318.76c148.05 112.652 284.48 270.064 296.07 446.684 11.32 180.78-93.51 298.856-204.31 416.476-4.19 4.45-8.38 8.89-12.57 13.33-106.86 113.18-215.37 228.12-226.75 400.13-4.58 68.43 5.8 138.19 16.17 207.81.52 3.47 1.03 6.94 1.55 10.41 10.84 73.09 20.84 145.97 12.72 217.08z"
      ></path>
    </svg>
  );
};

export default WeBuildCurve1;
