import React from "react";

const ContactPageBGCurve = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="141"
      height="136"
      fill="none"
      viewBox="0 0 148 146"
    >
      <path
        fill="#40BA77"
        fillRule="evenodd"
        d="M117.785 112.383c-16.784 17.241-36.944 36.596-60.593 32.18-22.785-4.251-31.038-30.692-41.71-51.267C6.406 75.803-4.262 57.272 2.267 38.678 8.791 20.102 27.94 10.318 46.706 4.37 64.634-1.31 83.244-.43 100.232 7.64c20.489 9.73 43.224 22.09 46.973 44.46 3.888 23.202-13.011 43.427-29.42 60.283z"
        clipRule="evenodd"
        opacity="0.08"
      ></path>
    </svg>
  );
};

export default ContactPageBGCurve;
