import React from "react";
// @ts-ignore
import arrow from "../../images/homePage/arrow.svg";
import classNames from "classnames";
import MaxWidthWrapper from "../common/MaxWidthWrapper";
import SecondaryWidthWrapper from "../common/SecondaryWidthWrapper";

interface ProcessListProps {
  titleSection: {
    primaryTitle?: string;
    secondaryTitle?: string;
    primaryDescription?: string;
    secondaryDescription?: string;
  };
  data: Array<{
    title: string;
    description: string;
    imageData: {
      url: string;
      altText: string;
      media: string;
    };
  }>;
}

const ProcessList: React.FC<ProcessListProps> = ({ titleSection, data }) => (
  <div
    className={classNames(
      "flex flex-col gap-y-[22px] py-[70px] xl:bg-gradient-to-b xl:to-hero-gradient-end xl:pt-[168px] xl:pb-[219px]",
      { "from-hero-gradient-start": true, "from-white": false }
    )}
  >
    <MaxWidthWrapper>
      <SecondaryWidthWrapper>
        <div
          className={
            "flex w-full items-center justify-center pb-[40px] lg:justify-start lg:pb-[113px]"
          }
        >
          <div>
            <h3
              className={
                "text-center text-[18px] font-[600] md:text-[35px] md:font-[600]"
              }
            >
              <span className={"text-[#192E35]"}>
                {`${titleSection.primaryTitle}\xa0`}
              </span>
              <span className={"text-primary"}>
                {titleSection.secondaryTitle}
              </span>
            </h3>
            <p
              className={
                "text-center text-[14px] text-[#2F4651] md:text-[22px] lg:text-left"
              }
            >
              <span className={"text-[#2F4651]"}>
                {`${titleSection.primaryDescription}`}
              </span>
            </p>
          </div>
        </div>
        <div className={"grid gap-y-[18px] lg:grid-cols-4 lg:gap-x-[50px]"}>
          {data?.map(({ description, title, imageData }, index) => (
            <div className={"w-full"} key={index}>
              <div
                className={
                  "grid gap-[40px] py-[20px] lg:grid-cols-1 xl:grid-cols-1 2xl:grid-cols-1"
                }
              >
                <div className={"flex items-center justify-start gap-8"}>
                  <img
                    className={"h-[40px] w-[40px] lg:h-[85px] lg:w-[85px]"}
                    src={`${imageData?.media}`}
                    alt={title}
                  />
                  <img
                    alt={"icon"}
                    src={arrow}
                    className={classNames(
                      "hidden lg:visible lg:block lg:w-1/4 xl:w-1/3 2xl:w-full",
                      {
                        "lg:hidden": index === data.length - 1,
                      }
                    )}
                  />
                </div>
                <div
                  className={"flex w-4/5 flex-col gap-[6px] lg:w-full lg:pt-12"}
                >
                  <p
                    className={
                      "text-left text-[16px] font-medium text-[#2F4651] lg:text-[22px]"
                    }
                  >
                    {title}
                  </p>
                  <p
                    className={
                      " text-left text-[14px] font-[400] text-[#455A64] lg:text-[18px]"
                    }
                  >
                    {description}
                  </p>
                </div>
              </div>
              <div
                className={classNames("h-[1px] w-full bg-green-100 lg:hidden", {
                  hidden: index === data.length - 1,
                })}
              />
            </div>
          ))}
        </div>
      </SecondaryWidthWrapper>
    </MaxWidthWrapper>
  </div>
);

export default ProcessList;
