import React from "react";

const WhyUsCurve2 = () => {
  return (
    <svg
      className={"md:w-[350px] lg:w-[500px] xl:w-[700px] 2xl:w-[1043px]"}
      xmlns="http://www.w3.org/2000/svg"
      // width="806"
      height="1082"
      fill="none"
      viewBox="0 0 776 1182"
    >
      <path
        stroke="#B0C5B2"
        strokeOpacity="0.6"
        d="M262.68 1093.28l-.398-.3c-37.2 51.01-81.283 77.05-127.356 84.94-46.084 7.9-94.212-2.34-139.497-23.99-67.714-33.87-125.263-85.56-166.75-149.75-91.987-143.891-144.216-334.748-140.508-506.754C-305.946 236.615-97.761-.196 164.218 1.364c234.253 1.374 469.836 170.175 570.271 381.768 37.394 78.28 58.65 173.314 20.396 251.265-39.403 79.694-114.425 109.036-192.061 137-2.938 1.058-5.88 2.114-8.824 3.171-74.781 26.851-150.804 54.147-198.128 125.643-18.848 28.43-31.434 60.461-43.984 92.402l-1.88 4.782c-13.197 33.535-26.7 66.775-47.726 95.585l.398.3z"
      ></path>
    </svg>
  );
};

export default WhyUsCurve2;
