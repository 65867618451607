import React from "react";

const JoinUsCurve2 = () => {
  return (
    <svg
      className={"md:w-[255px] lg:w-[355px] xl:w-[455px] 2xl:w-[624px]"}
      xmlns="http://www.w3.org/2000/svg"
      // width="624"
      height="1080"
      fill="none"
      viewBox="0 0 624 959"
    >
      <path
        stroke="#B0C5B2"
        strokeOpacity="0.6"
        d="M874.364 283.508c-.508-71.796-51.033-132.711-107.153-175.067C615.962-6.322 381.245-37.216 208.841 53.224 15.966 154.382-49.964 408.027 41.341 600.133m833.023-316.625h-.496m.496 0l-.496.001v-.001m.496 0c.315 76.272-47.485 128.001-96.023 180.528-45.015 48.715-90.664 98.115-99.155 168.416m194.682-348.944c-.506-71.553-50.871-132.337-106.951-174.662l-.001-.001C615.805-5.814 381.29-36.671 209.07 53.673 16.459 154.692-49.415 408.027 41.787 599.914m832.081-316.406c.304 73.278-43.93 123.851-90.61 174.465-1.768 1.917-3.54 3.834-5.313 5.753-44.986 48.683-90.74 98.194-99.25 168.662M41.787 599.914s0 0 0 0l-.446.219m.446-.219l-.446.219m.446-.219c60.279 126.495 168.468 244.884 288.598 313.621M41.341 600.133c60.323 126.586 168.583 245.053 288.804 313.842m0 0l.239-.441.001.001m-.24.44c53.993 30.531 115.19 45.685 177.318 43.908m-177.318-43.908l.241-.44h-.001m0 0c53.913 30.485 115.02 45.617 177.058 43.843m.02.505l-.024-.504.004-.001m.02.505c82.377-3.851 156.505-45.359 173.715-148.749M507.463 957.883l-.016-.505h-.004m0 0c41.116-1.922 80.136-13.241 111.016-36.946 30.871-23.698 53.645-59.804 62.231-111.384l.488.086m0 0l-.488-.086c4.861-29.135 2.587-58.559-.022-88.092l-.374-4.213c-2.499-28.117-5.005-56.324-1.599-84.355m2.483 176.746c5.105-30.591 2.359-61.489-.389-92.419-2.5-28.13-5.002-56.287-1.603-84.263m-.491-.064l.491.064m-.491-.064s0 0 0 0l.491.064"
      ></path>
    </svg>
  );
};

export default JoinUsCurve2;
