import OurTechnologyType from "../../types/ourTechnology.type";

const ourTechnologiesHandler = (
  technologiesData: Array<OurTechnologyType> | undefined
) => {
  let ourTechnologyData: Array<{
    name: string;
    imageData: {
      media: string;
      url: string;
      altText: string;
    };
    visibleOnHome: boolean;
  }> = [];
  if (technologiesData) {
    technologiesData?.forEach((techData) => {
      if (techData?.attributes?.visibleOnHome === true) {
        const tempTechData = {
          name: techData?.attributes?.name,
          imageData: {
            media: `${techData?.attributes?.imageData?.media?.data?.[0]?.attributes?.url}`,
            url: techData?.attributes?.imageData?.url,
            altText: techData?.attributes?.imageData?.altText,
          },
          visibleOnHome: true,
        };
        ourTechnologyData = [...ourTechnologyData, tempTechData];
      }
    });
  }
  return ourTechnologyData;
};
export default ourTechnologiesHandler;
