import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "gatsby";
import Project from "./Project";
import MaxWidthWrapper from "../../components/common/MaxWidthWrapper";
import SecondaryWidthWrapper from "../common/SecondaryWidthWrapper";

import { Autoplay, Scrollbar } from "swiper";

import "swiper/css";
import "swiper/css/scrollbar";
import trackScrollEvent from "../../util/trackScrollEvent";

export interface Testimonial {
  name: string;
  position: string;
  feedback: string;
  location: string;
  imageData: {
    altText: string;
    url: string;
    media: string;
  };
}

export interface ClientTypes {
  name: string;
  linkedIn: string;
  description: string;
  companyLogo: {
    altText: string;
    url: string;
    media: string;
  };
  duration: string;
  location: string;
  testimonials: Array<Testimonial>;
  order: number;
  // todo assign type
  techUsed: Array<{ src: string; altText: string }>;
  platform?: Array<{ src: string; altText: string }>;
  slug: string;
  tags: Array<string>;
  projectImages: Array<{ altText: string; url: string; media: string }>;
  invert?: boolean | number;
  showLabel?: boolean;
}

export interface TopProjectProps {
  primaryTitle: string;
  secondaryTitle: string;
  button?: {
    text: string;
    url: string;
  };
  clients: Array<ClientTypes>;
  tags: Array<string>;
  selectedCate: string;
  setSelectedCate(cate: string): void;
}

const TopProject: React.FC<TopProjectProps> = ({
  clients,
  secondaryTitle,
  primaryTitle,
  button,
  tags,
  selectedCate,
  setSelectedCate,
}) => {
  const [isInRange, setInRange] = useState<any>(false);

  const handleScroll = () => {
    setInRange(trackScrollEvent(300, "topProject"));
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div
      className={"bg-hero-gradient-start pt-[60px] lg:relative lg:pt-[180px]"}
    >
      <MaxWidthWrapper>
        <SecondaryWidthWrapper>
          <p
            className={
              "text-center text-[18px] font-medium leading-[27px] lg:text-left lg:text-[35px] lg:font-semibold"
            }
          >
            {primaryTitle} &nbsp;
            <span className={"text-primary"}>{secondaryTitle}</span>
          </p>
          <div
            className={
              "no-scrollbar m-auto flex justify-center gap-x-[34px] overflow-x-auto pt-[20px] text-[14px] font-medium lg:w-full lg:justify-start lg:pt-[36px] lg:text-[25px] lg:font-normal lg:leading-[37.5px] lg:leading-[42px] lg:text-gray-300"
            }
          >
            <div className={"z-10 mb-[25px] flex justify-center gap-[25px]"}>
              {tags?.map((tag, index) => (
                <p
                  key={index}
                  className={"cursor-pointer"}
                  style={{
                    color: selectedCate === tag ? "#7BD4AB" : "#111111",
                  }}
                  onClick={() => setSelectedCate(tag)}
                >
                  {tag}
                </p>
              ))}
            </div>
          </div>
          <Swiper
            key={`${isInRange}`}
            id={"topProject"}
            scrollbar={{
              draggable: true,
            }}
            autoplay={
              isInRange
                ? {
                    delay: 5000,
                    disableOnInteraction: true,
                  }
                : false
            }
            modules={[Scrollbar, Autoplay]}
            className={"mySwiper"}
          >
            {clients?.map((client, index) => (
              <SwiperSlide key={index}>
                <div style={{ height: "auto", width: "100%" }}>
                  <Project {...client} invert={0} />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          {button && (
            <div className={"flex justify-center"}>
              <button
                className={
                  "my-16 flex justify-center rounded-md bg-green-300 text-white"
                }
                style={{ position: "inherit", zIndex: 1 }}
              >
                <Link
                  className={
                    "px-8 py-2 text-[14px] font-semibold leading-[21px] lg:rounded-lg lg:bg-primary lg:py-4 lg:px-16 lg:text-[22px] lg:font-medium lg:leading-[33px]"
                  }
                  to={button?.url}
                >
                  {button?.text}
                </Link>
              </button>
            </div>
          )}
        </SecondaryWidthWrapper>
      </MaxWidthWrapper>
    </div>
  );
};

export default TopProject;
