import React from "react";

const ProjectsCurve1 = () => {
  return (
    <svg
      className={"lg:w-[880px]  xl:w-[1080px] 2xl:w-[1329px]"}
      xmlns="http://www.w3.org/2000/svg"
      // width="1329"
      height="1850"
      fill="none"
      viewBox="0 0 1329 1520"
    >
      <path
        stroke="#B0C5B2"
        strokeOpacity="0.35"
        d="M1053.9 327.157l.09-.469-.09.469c96.89 13.974 164.21 52.992 207.88 106.391 43.68 53.408 63.74 121.24 66.02 192.879 1.34 108.099-29.14 214.903-88.31 309.467-133.3 210.646-359.696 401.446-599.806 508.926-364.298 162.65-839.231 52.75-1023.236-281.01-164.542-298.379-98.841-707.63 121.946-973.173C-180.1 92.102-63.952 3.072 70.944.806 209.06-1.14 302.925 75.046 396.66 155.368c3.542 3.035 7.083 6.076 10.627 9.118C497.484 241.93 589.1 320.591 721.314 334.041c52.603 5.387 105.73.48 158.742-4.416l7.93-.731c55.663-5.114 111.17-9.651 165.914-1.737z"
      ></path>
    </svg>
  );
};

export default ProjectsCurve1;
