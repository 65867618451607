import React from "react";
import MaxWidthWrapper from "../../components/common/MaxWidthWrapper";
import PrimaryBlockType from "../../types/PrimaryBlock.type";
import { primaryBlockDataHandler } from "../../helper/api/primaryBlock.api";
import classNames from "classnames";
import SecondaryWidthWrapper from "../common/SecondaryWidthWrapper";

interface OurExperienceComponentProps {
  ourExperiencesData: PrimaryBlockType;
}

const OurExperienceComponent: React.FC<OurExperienceComponentProps> = ({
  ourExperiencesData,
}) => {
  const ourExperienceData = primaryBlockDataHandler(ourExperiencesData);
  return (
    <div
      className={classNames(
        "bg-gradient-to-b to-hero-gradient-end py-[24px]  md:py-[80px] ",
        { "from-hero-gradient-start": true, "from-white": false }
      )}
    >
      <MaxWidthWrapper>
        <SecondaryWidthWrapper>
          {/*Title Section*/}
          <div
            className={"mb-[18px] flex justify-center pb-[23px] md:mb-[48px]"}
          >
            <div>
              <h3
                className={
                  "pb-[6px] text-center text-[18px] font-[500] md:text-[35px] md:font-[600]"
                }
              >
                <span className={"text-[#192E35]"}>
                  {`${ourExperienceData.titleSection.primaryTitle}\xa0`}
                </span>
                <span className={"text-primary"}>
                  {ourExperienceData.titleSection.secondaryTitle}
                </span>
              </h3>
              <p
                className={
                  "m-auto w-[90%] text-center text-[14px] text-[#2F4651] md:w-[70%] md:text-[22px]"
                }
              >
                <span className={"text-[#192E35]"}>
                  {`${ourExperienceData.titleSection.primaryDescription}`}
                </span>
              </p>
            </div>
          </div>

          {/*Grid of all the experience component*/}
          <div
            className={
              "grid grid-cols-1 gap-y-[19px] gap-x-[3rem] md:grid-cols-2 md:gap-y-[5rem] lg:grid-cols-3"
            }
          >
            {ourExperienceData.dataSection.map((blockData, index) => {
              return (
                <div
                  key={index}
                  className={
                    "flex flex-col items-center gap-[8px] bg-[#EBF9F0] py-[26px] md:items-start md:gap-[15px] md:bg-inherit md:py-0"
                  }
                >
                  {/*ICON DIV*/}
                  <div className={"h-[90px] w-[90px]"}>
                    <img
                      src={blockData.imageData?.media}
                      alt={blockData.imageData?.altText}
                      style={{ width: "100%", height: "100%" }}
                    />
                  </div>
                  {/*TITLE DIV*/}
                  <div>
                    <p
                      className={
                        "text-[16px] font-[500] text-primary md:text-[18px]"
                      }
                    >
                      {blockData.title}
                    </p>
                  </div>
                  {/*DESCRIPTION DIV*/}
                  <div className={"w-[70%] md:w-[80%]"}>
                    <p
                      className={
                        "text-center text-[14px] text-[#484747] md:text-left md:text-[16px]"
                      }
                    >
                      {blockData.description}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </SecondaryWidthWrapper>
      </MaxWidthWrapper>
    </div>
  );
};
export default OurExperienceComponent;
